import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  memberRequestStarted,
  memberRequestSuccess,
  memberRequestFail,
} from "../Members";

export async function fetchMembersApi(url) {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl = window.siteSettings.baseUrl;

  try {
    const body = {
      what: "limited",
    };

    return await axios.request({
      url: baseUrl + "/api/members_get.php",
      method: "POST",
      data: JSON.stringify(body),
      header: { "content-type": "application/json" },
    });
  } catch (e) {
    console.log(e);
  }
}
export function* fetchMembers() {
  try {
    let result = yield call(() => fetchMembersApi());
    yield put(memberRequestSuccess(result.data));
  } catch (error) {
    yield put(memberRequestFail());
  }
}

function* memberSaga() {
  yield takeLatest(memberRequestStarted, fetchMembers);
}
export default memberSaga;
