import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Settings",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    fetchSettingsStarted: (state, action) => {
      state.loading = true;
    },
    fetchSettingsSuccess: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    fetchSettingsFailed: (state, action) => {
      state.loading = false;
      state.list = [];
    },
  },
});

export const {
  fetchSettingsStarted,
  fetchSettingsSuccess,
  fetchSettingsFailed,
} = slice.actions;
export default slice.reducer;
