import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import configStore from "./store/configStore";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorBoundary from "./components/Errors/ErrorBoundary";
const store = configStore;
let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
const CLIENT_ID =
  "735267353871-617gsesd80fl77rjbm785es2r29elsvr.apps.googleusercontent.com";
// "297736994767-ih19sgn54h83qbl88hcu6bjiosnbm89q.apps.googleusercontent.com";
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ErrorBoundary>
          <App gapiID={CLIENT_ID} />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
