import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "members",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    memberRequestStarted: (members, action) => {
      members.loading = true;
    },
    memberRequestSuccess: (members, action) => {
      members.loading = false;
      members.list = action.payload;
      members.lastFetch = new Date();
    },
    memberRequestFail: (members, action) => {
      members.loading = false;
      members.list = [];
    },
  },
});

export const { memberRequestStarted, memberRequestSuccess, memberRequestFail } =
  slice.actions;
export default slice.reducer;
