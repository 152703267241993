import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "BtacaPost",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    fetchBtacaPostStarted: (state, action) => {
      state.loading = true;
    },
    fetchBtacaPostSuccess: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    fetchBtacaPostFailed: (state, action) => {
      state.loading = false;
      state.list = [];
    },
  },
});

export const {
  fetchBtacaPostStarted,
  fetchBtacaPostSuccess,
  fetchBtacaPostFailed,
} = slice.actions;
export default slice.reducer;
