import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Constituent",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    fetchConstituentStarted: (constituent, action) => {
      constituent.loading = true;
    },
    fetchConstituentSuccess: (constituent, action) => {
      constituent.loading = false;
      constituent.list = action.payload;
    },
    fetchConstituentFailed: (constituent, action) => {
      constituent.loading = false;
      constituent.list = [];
    },
  },
});

export const {
  fetchConstituentStarted,
  fetchConstituentSuccess,
  fetchConstituentFailed,
} = slice.actions;
export default slice.reducer;
