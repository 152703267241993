import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  articleFetchStarted,
  articleFetchSuccess,
  articleFetchFailed,
} from "../Article";

export async function postArticleApi(url, article) {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl = window.siteSettings.baseUrl;

  try {
    let body = { what: "all" };
    let url = baseUrl + "/api/article_get.php";

    return await axios({
      url: url,
      method: "POST",
      data: JSON.stringify(body),
      header: { "content-type": "application/json" },
    });
  } catch (e) {
    console.log(e);
  }
}

export function* postArticle() {
  try {
    let result = yield call(() => postArticleApi());
    yield put(articleFetchSuccess(result.data));
  } catch (error) {
    yield put(articleFetchFailed());
  }
}

function* memberSaga() {
  yield takeLatest(articleFetchStarted, postArticle);
}
export default memberSaga;
