import { all, fork } from "redux-saga/effects";
import memberSaga from "./memberSaga";
import articleSaga from "./articleSaga";
import btacaPostSaga from "./btacaPostSaga";
import constituentSaga from "./constituentSaga";
import settingsSaga from "./settingsSaga";
import companySaga from "./companySaga";

export default function* rootSaga() {
  yield all([
    fork(memberSaga),
    fork(articleSaga),
    fork(btacaPostSaga),
    fork(constituentSaga),
    fork(settingsSaga),
    fork(companySaga),
  ]);
}
