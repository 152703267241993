import React, { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import "./Social.module.scss";
import { useSelector } from "react-redux";

export const SocialLink = ({
  count = 0,
  useBgColor = "true",
  textColor = "black",
}) => {
  const [loading, setLoading] = useState(false);
  const [sList, setSList] = useState([]);

  const baseUrl = window.siteSettings.baseUrl;
  const getList = async () => {
    setLoading(true);
    const body = {
      what: "active",
    };
    try {
      let url = baseUrl + "/api/social_get.php";

      const res = await axios({
        url: url,
        method: "POST",
        data: JSON.stringify(body),
        header: { "content-type": "application/json" },
      });

      if (res.data.error !== null) {
        if (res.data.error === "true") {
          setLoading(false);
        } else {
          setSList(res.data);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error occured");
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <>
      <div>
        <Oval
          height="30"
          width="30"
          radius="5"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={loading}
        />

        <div className="flex items-center justify-center m-0 p-0 flex-column">
          {sList &&
            !loading &&
            sList.map((a) => {
              return (
                <a
                  key={a.id}
                  href={a.link}
                  target="_blank"
                  className="text-decoration-none rounded link mb-1"
                  alt={a.sname}
                  rel="noreferrer"
                >
                  <img
                    src={baseUrl + "/assets/social/" + a.img_file_name}
                    alt={a.title}
                    className="h-8 w-8"
                  />
                </a>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SocialLink;
