import React, { useEffect } from "react";
import Router from "./router";
import "./App.css";
import "./spinner.css";
import { fetchSettingsStarted } from "./store/Settings";
import { fetchCompanyStarted } from "./store/Company";
import { memberRequestStarted } from "./store/Members";
import { articleFetchStarted } from "./store/Article";
import { fetchConstituentStarted } from "./store/Constituent";
import { useDispatch } from "react-redux";
import SocialLink from "./components/SocialLink";
import TopbarMsg from "./components/TopbarMsg";
// import store from "./store/configStore";

const CLIENT_ID =
  "735267353871-617gsesd80fl77rjbm785es2r29elsvr.apps.googleusercontent.com";
// "297736994767-ih19sgn54h83qbl88hcu6bjiosnbm89q.apps.googleusercontent.com";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(articleFetchStarted());
    dispatch(fetchSettingsStarted());
    dispatch(fetchCompanyStarted());
    dispatch(memberRequestStarted());
    dispatch(fetchConstituentStarted());
  }, []);
  return (
    <>
      <TopbarMsg />

      <div className="container">
        <Router />
      </div>
    </>
  );
}

export default App;
