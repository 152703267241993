import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  fetchSettingsStarted,
  fetchSettingsSuccess,
  fetchSettingsFailed,
} from "../Settings";
// import store from "../configStore";

export async function postApi() {
  // const settings = store.getState().reducer.settingsReducer.list;
  // const baseUrl = settings.filter((s) => s.setting_name === "base_url")[0]
  //   .config_value;
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl = window.siteSettings.baseUrl;

  try {
    let body = { what: "all" };
    let url = baseUrl + "/api/settings_get.php";

    return await axios({
      url: url,
      method: "POST",
      data: JSON.stringify(body),
      header: { "content-type": "application/json" },
    });
  } catch (e) {
    console.log(e);
  }
}

export function* postSettings() {
  try {
    let result = yield call(() => postApi());
    yield put(fetchSettingsSuccess(result.data));
  } catch (error) {
    yield put(fetchSettingsFailed());
  }
}

function* settingsSaga() {
  yield takeLatest(fetchSettingsStarted, postSettings);
}
export default settingsSaga;
