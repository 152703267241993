import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "TopBarMsg",
  initialState: {
    message: "",
    show: false,
    title: "",
    messageType: "success",
  },

  reducers: {
    showMessageBar: (state, action) => {
      state.show = true;
      state.messageType = action.payload.messageType;
      state.title = action.payload.title;
      state.message = action.payload.message;
    },
    closeMessageBar: (state) => {
      state.show = false;
      state.messageType = "";
      state.title = "";
      state.message = "";

    },
  },
});

export const { showMessageBar, closeMessageBar } = slice.actions;

export default slice.reducer;
