import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "modalentry",
  initialState: {
    show: false,
    what: null,
  },

  reducers: {
    showModalEntry: (modalentry, action) => {
      modalentry.show = true;
      modalentry.what = action.payload.what;
    },
    hideModalEntry: (modalentry, action) => {
      modalentry.show = false;
      modalentry.what = null;
    },
  },
});

export const { showModalEntry, hideModalEntry } = slice.actions;
export default slice.reducer;
