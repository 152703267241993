import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    currentUser: [],
    loggedIn: false,
    loading: false,
    lastFetch: null,
    openLogin: false,
    role: null,
    memberId: null,
    openRegisterUser: false,
    loginExpiresIn: null,
  },
  reducers: {
    userInfoReveived: (user, action) => {
      user.loading = false;
      user.loggedIn = true;
      user.currentUser = action.payload;
      user.memberId = action.payload.id;
      user.role = action.payload.role;
      user.loginExpiresIn = action.payload.login_expires_in;

    },
    userLogOut: (user, action) => {
      user.loggedIn = false;
      user.loading = false;
      user.role = null;
      user.memberId = null;
      user.loginExpiresIn = null;
      user.currentUser = [];
    },
    openLoginPopup: (user, action) => {
      user.openLogin = action.payload;
    },
    openRegisterUserPopup: (user, action) => {
      user.openRegisterUser = action.payload;
    },
  },
});

export const {
  userInfoReveived,
  userLogOut,
  openLoginPopup,
  openRegisterUserPopup,
} = slice.actions;
export default slice.reducer;
