import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Company",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    fetchCompanyStarted: (state, action) => {
      state.loading = true;
    },
    fetchCompanySuccess: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    fetchCompanyFailed: (state, action) => {
      state.loading = false;
      state.list = [];
    },
  },
});

export const { fetchCompanyStarted, fetchCompanySuccess, fetchCompanyFailed } =
  slice.actions;
export default slice.reducer;
