import React from "react";
// import Header from "../Header";
import { BiError } from "react-icons/bi"
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null, errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }


    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI

            return (
                <>
                    <div className="container">
                        {/* <Header /> */}
                        <div className="alert alert-danger d-flex flex-column justify-content-center align-items-center p-5 m-5">
                            <BiError className="text-danger" style={{ fontSize: "5rem" }} />
                            <h1>Oops!</h1>
                            <h4>Something went wrong</h4>
                            Sorry for the inconvenience caused...
                            <div>Goto home page. <a href="/">here</a>
                            </div>
                            {this.state.errorInfo &&
                                <details style={{ whiteSpace: 'pre-wrap', color: "black" }}>
                                    {this.state.error && this.state.error.toString()}
                                    <br />
                                    {this.state.errorInfo.componentStack}
                                </details>
                            }
                        </div>
                    </div>
                </>


            )
        }

        return this.props.children;
    }
}
