import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "mearticlesmbers",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    articleFetchStarted: (article, action) => {
      article.loading = true;
    },
    articleFetchSuccess: (article, action) => {
      article.loading = false;
      article.list = action.payload;
      article.lastFetch = new Date();
    },
    articleFetchFailed: (article, action) => {
      article.loading = true;
    },
    articlePostStarted: (article, action) => {
      article.loading = true;
    },
    articlePostSuccess: (article, action) => {
      article.loading = false;
    },
    articlePostFailed: (article, action) => {
      article.loading = false;
    },
  },
});

export const {
  articleFetchStarted,
  articleFetchSuccess,
  articleFetchFailed,
  articlePostStarted,
  articlePostSuccess,
  articlePostFailed,
} = slice.actions;

export default slice.reducer;
