import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  fetchConstituentStarted,
  fetchConstituentSuccess,
  fetchConstituentFailed,
} from "../Constituent";

export async function fetchConstituentApi(url, constituent) {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl = window.siteSettings.baseUrl;

  try {
    return await axios.request({
      url: baseUrl + "/api/constituents_get.php",
    });
  } catch (e) {
    console.log(e);
  }
}
export function* fetchConstituent() {
  try {
    let result = yield call(() => fetchConstituentApi());

    yield put(fetchConstituentSuccess(result.data));
  } catch (error) {
    yield put(fetchConstituentFailed());
  }
}

function* constituentSaga() {
  yield takeLatest(fetchConstituentStarted, fetchConstituent);
}
export default constituentSaga;
