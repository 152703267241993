import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  fetchBtacaPostStarted,
  fetchBtacaPostSuccess,
  fetchBtacaPostFailed,
} from "../BtacaPost";
export async function fetchBtacaPostApi(url, btacapost) {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl = window.siteSettings.baseUrl


  try {
    return await axios.request({
      url: baseUrl + "/api/btacapost_get.php",
    });
  } catch (e) {
    console.log(e);
  }
}
export function* fetchBtacaPost() {
  try {
    let result = yield call(() => fetchBtacaPostApi());
    yield put(fetchBtacaPostSuccess(result.data));
  } catch (error) {
    yield put(fetchBtacaPostFailed());
  }
}

function* btacaPostSaga() {
  yield takeLatest(fetchBtacaPostStarted, fetchBtacaPost);
}
export default btacaPostSaga;
