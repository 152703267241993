import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeMessageBar } from "../../store/TopbarMsg";

export const TopbarMsg = () => {
  const dispatch = useDispatch();
  const [bg, setBg] = useState("");
  const [textColor, setTextColor] = useState("");
  const alert = useSelector((state) => state.reducer.topBarMsg);
  const close = () => {
    dispatch(closeMessageBar());
  };
  useEffect(() => {
    if (alert.messageType === "success") {
      setBg("green-700");
      setTextColor("gray-100");
    } else if (alert.messageType === "danger") {
      setBg("red-500");
      setTextColor("gray-100");
    } else if (alert.messageType === "info") {
      setBg("blue-500");
      setTextColor("white");
    } else {
      setBg("black");
      setTextColor("white");
    }
  }, [alert]);

  useEffect(() => {
    setTimeout(() => {
      close();
    }, 9000);
  }, [alert.show]);

  if (!alert.show) return;

  return (
    <>
      <div className="bg-yellow-500 bg-green-700 bg-blue-600 bg-black bg-red-500 bg-yellow-500 bg-slate-900 text-gray-100 hidden"></div>

      <div
        className={`w-screen p-3 text-${textColor} sticky z-50 top-0 right-0 left-0 flex justify-center items-center bg-${bg}`}
      >
        <div
          className="flex justify-center items-center absolute top-0 right-[25px] w-[25px] h-[25px] rounded-full bg-gray-500 text-white text-[14px] cursor-pointer"
          onClick={close}
        >
          X
        </div>
        <div className="flex flex-col justify-center items-center">
          <div
            className="font-bold"
            dangerouslySetInnerHTML={{ __html: alert.title }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: alert.message }}></div>
        </div>
      </div>
    </>
  );
};

export default TopbarMsg;
