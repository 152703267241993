import { combineReducers } from "redux";
// import TopbarmsgReducer from "./Topbarmsg";
import topBarMsgReducer from "./TopbarMsg";

import AlertPopupReducer from "./AlertPopup";

import membersReducer from "./Members";
import userReducer from "./User";
import articleReducer from "./Article";
// import pageLocationReducer from "./PageLocation";
import btacaPostReducer from "./BtacaPost";
import settingsReducer from "./Settings";

import constituentReducer from "./Constituent";
import modalEntryReducer from "./ModalEntry";
import companyReducer from "./Company";

const Reducers = combineReducers({
  // Topbarmsg: TopbarmsgReducer,
  topBarMsg: topBarMsgReducer,

  companyReducer: companyReducer,
  AlertPopup: AlertPopupReducer,
  Members: membersReducer,
  CurrentUser: userReducer,
  Article: articleReducer,
  // PageLocation: pageLocationReducer,
  BtacaPost: btacaPostReducer,
  Constituent: constituentReducer,
  ModalEntry: modalEntryReducer,
  settingsReducer: settingsReducer,
});

export default Reducers;
