import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducers from "./Reducers";
import createSagaMiddleware from "redux-saga";
import saga from "./sagas/rootSaga";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);

// export const configStore = () => {
//   return configureStore({
//     reducer: reducers,
//     //middleWare: [...getDefaultMiddleware(), saga],
//     middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware().concat(middleware),
//   });
// };

export const configStore = configureStore({
  reducer: {
    reducer: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

sagaMiddleware.run(saga);

export default configStore;
