import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  fetchCompanyStarted,
  fetchCompanySuccess,
  fetchCompanyFailed,
} from "../Company";

export async function postApi() {
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrl = window.siteSettings.baseUrl;

  try {
    let body = { what: "all" };
    let url = baseUrl + "/api/company_get.php";

    return await axios({
      url: url,
      method: "POST",
      data: JSON.stringify(body),
      header: { "content-type": "application/json" },
    });
  } catch (e) {
    console.log(e);
  }
}
export function* postCompany() {
  try {
    let result = yield call(() => postApi());
    yield put(fetchCompanySuccess(result.data));
  } catch (error) {
    yield put(fetchCompanyFailed());
  }
}

function* settingsSaga() {
  yield takeLatest(fetchCompanyStarted, postCompany);
}
export default settingsSaga;
