import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import loadable from "@loadable/component";
import { Watch } from "react-loader-spinner";

const Loading = (props) => {
  if (props.error) {
    return (
      <div classname="flex jutify-center items-center p-5 mt-5">
        Error Occurred!
      </div>
    );
  } else {
    return (
      <div className="flex justify-center items-center p-5 mt-5">
        <Watch
          height="80"
          width="80"
          radiuys="48"
          color="#4fa94d"
          ariaLable="watch-loading"
          wrapStyle
          visible={true}
        />

        <div className="text-[18px] text-green-800 mt-2">Loading</div>
      </div>
    );
  }
};

const Home = loadable(
  () => import("../frontend/Home" /* webpackChunkName: "home" */),
  {
    fallback: <Loading />,
  }
);

const About = loadable(
  () => import("../frontend/About" /* webpackChunkName: "About" */),
  {
    fallback: <Loading />,
  }
);

const EventAttendees = loadable(
  () =>
    import(
      "../frontend/Events/EventAttendees" /* webpackChunkName: "EventAttendees" */
    ),
  {
    fallback: <Loading />,
  }
);

const EventDetail = loadable(
  () =>
    import(
      "../frontend/Events/EventDetail" /* webpackChunkName: "EventDetail" */
    ),
  {
    fallback: <Loading />,
  }
);

const EventAttendeeList = loadable(
  () =>
    import(
      "../frontend/Events/EventAttendeeList" /* webpackChunkName: "EventAttendeeList" */
    ),
  {
    fallback: <Loading />,
  }
);

const Events = loadable(
  () => import("../frontend/Events" /* webpackChunkName: "Events" */),
  {
    fallback: <Loading />,
  }
);

const Contact = loadable(
  () => import("../frontend/Contact" /* webpackChunkName: "Contact" */),
  {
    fallback: <Loading />,
  }
);

const MembersList = loadable(
  () => import("../frontend/MembersList" /* webpackChunkName: "MembersList" */),
  {
    fallback: <Loading />,
  }
);

const Charter = loadable(
  () => import("../frontend/Charter" /* webpackChunkName: "Charter" */),
  {
    fallback: <Loading />,
  }
);

const AdDisplay = loadable(
  () => import("../frontend/AdDisplay" /* webpackChunkName: "AdDisplay" */),
  {
    fallback: <Loading />,
  }
);

const Popmsg = loadable(
  () => import("../components/Popmsg" /* webpackChunkName: "Popmsg" */),
  {
    fallback: <Loading />,
  }
);

const AlertPopup = loadable(
  () =>
    import("../components/AlertPopup/" /* webpackChunkName: "AlertPopup" */),
  {
    fallback: <Loading />,
  }
);

const Header = loadable(
  () => import("../components/Header" /* webpackChunkName: "Header" */),
  {
    fallback: <Loading />,
  }
);

const Footer = loadable(
  () => import("../components/Footer" /* webpackChunkName: "Footer" */),
  {
    fallback: <Loading />,
  }
);

const MemberLogin = loadable(
  () => import("../frontend/MemberLogin" /* webpackChunkName: "MemberLogin" */),
  {
    fallback: <Loading />,
  }
);

const MemberRegister = loadable(
  () =>
    import(
      "../frontend/MemberRegister" /* webpackChunkName: "MemberRegister" */
    ),
  {
    fallback: <Loading />,
  }
);

const TermsCondition = loadable(
  () =>
    import(
      "../frontend/TermsCondition" /* webpackChunkName: "TermsCondition" */
    ),
  {
    fallback: <Loading />,
  }
);

const Pages = loadable(
  () => import("../frontend/Pages" /* webpackChunkName: "Pages" */),
  {
    fallback: <Loading />,
  }
);

const ViewAlbumList = loadable(
  () =>
    import("../frontend/GooglePhoto/" /* webpackChunkName: "ViewAlbumList" */),
  {
    fallback: <Loading />,
  }
);

const Photos = loadable(
  () =>
    import("../frontend/GooglePhoto/Photos" /* webpackChunkName: "Photos" */),
  {
    fallback: <Loading />,
  }
);
const PageNotFound = loadable(
  () =>
    import("../frontend/PageNotFound" /* webpackChunkName: "PageNotFound" */),
  {
    fallback: <Loading />,
  }
);
const Forgotpassword = loadable(
  () =>
    import(
      "../frontend/Forgotpassword" /* webpackChunkName: "Forgotpassword" */
    ),
  {
    fallback: <Loading />,
  }
);
const Resetpassword = loadable(
  () =>
    import("../frontend/Resetpassword" /* webpackChunkName: "Resetpassword" */),
  {
    fallback: <Loading />,
  }
);
const Donate = loadable(
  () => import("../frontend/Donate" /* webpackChunkName: "Donate" */),
  {
    fallback: <Loading />,
  }
);

const Dashboard = loadable(
  () => import("../admin/Dashboard" /* webpackChunkName: "Dashboard" */),
  {
    fallback: <Loading />,
  }
);

const NotAuthorized = loadable(
  () =>
    import("../admin/NotAuthorized" /* webpackChunkName: "NotAuthorized" */),
  {
    fallback: <Loading />,
  }
);
const GooglePhoto = loadable(
  () =>
    import("../admin/GooglePhotoApi/" /* webpackChunkName: "GooglePhoto" */),
  {
    fallback: <Loading />,
  }
);

const PollList = loadable(
  () => import("../admin/Poll/" /* webpackChunkName: "Poll" */),
  {
    fallback: <Loading />,
  }
);

const PollVote = loadable(
  () => import("../admin/PollVote/" /* webpackChunkName: "PollVote" */),
  {
    fallback: <Loading />,
  }
);

const PollGroupList = loadable(
  () => import("../admin/PollGroup/" /* webpackChunkName: "PollGroupList" */),
  {
    fallback: <Loading />,
  }
);

const PollQuestionsList = loadable(
  () =>
    import(
      "../admin/PollQuestions/" /* webpackChunkName: "PollQuestionsList" */
    ),
  {
    fallback: <Loading />,
  }
);

const VoteCount = loadable(
  () =>
    import("../admin/PollVote/VoteCount" /* webpackChunkName: "VoteCount" */),
  {
    fallback: <Loading />,
  }
);

const VoteCheck = loadable(
  () =>
    import("../admin/PollVote/VoteCheck" /* webpackChunkName: "VoteCheck" */),
  {
    fallback: <Loading />,
  }
);

const AddModifyArticle = loadable(
  () =>
    import(
      "../admin/Article/AddModifyArticle" /* webpackChunkName: "AddModifyArticle" */
    ),
  {
    fallback: <Loading />,
  }
);

const ArticleList = loadable(
  () =>
    import(
      "../admin/Article/ArticleList" /* webpackChunkName: "ArticleList" */
    ),
  {
    fallback: <Loading />,
  }
);

const EventsList = loadable(
  () => import("../admin/Events/" /* webpackChunkName: "EventsList" */),
  {
    fallback: <Loading />,
  }
);
const Profile = loadable(
  () => import("../admin/Profile/" /* webpackChunkName: "Profile" */),
  {
    fallback: <Loading />,
  }
);
const Members = loadable(
  () =>
    import(
      "../admin/Members/MembersList" /* webpackChunkName: "AdminMembers" */
    ),
  {
    fallback: <Loading />,
  }
);
const MemberAddModify = loadable(
  () =>
    import(
      "../admin/Members/MemberAddModify" /* webpackChunkName: "MemberAddModify" */
    ),
  {
    fallback: <Loading />,
  }
);

const BeneficiaryList = loadable(
  () =>
    import(
      "../admin/Members/Beneficiary" /* webpackChunkName: "beneficiary" */
    ),
  {
    fallback: <Loading />,
  }
);

const ResetMember = loadable(
  () =>
    import(
      "../admin/Members/ResetMember" /* webpackChunkName: "ResetMember" */
    ),
  {
    fallback: <Loading />,
  }
);
const Post = loadable(
  () => import("../admin/Members/Post" /* webpackChunkName: "Post" */),
  {
    fallback: <Loading />,
  }
);
const Assets = loadable(
  () => import("../admin/Assets/" /* webpackChunkName: "Assets" */),
  {
    fallback: <Loading />,
  }
);
const Settings = loadable(
  () => import("../admin/Settings/" /* webpackChunkName: "Settings" */),
  {
    fallback: <Loading />,
  }
);

const Menu = loadable(
  () => import("../admin/Menu/" /* webpackChunkName: "Menu" */),
  {
    fallback: <Loading />,
  }
);

const Company = loadable(
  () => import("../admin/Company/" /* webpackChunkName: "Company" */),
  {
    fallback: <Loading />,
  }
);
const MemberPaymentAddModify = loadable(
  () =>
    import(
      "../admin/Finance/Fees/MemberPaymentAddModify" /* webpackChunkName: "MemberPaymentAddModify" */
    ),
  {
    fallback: <Loading />,
  }
);
const MemberPaymentList = loadable(
  () =>
    import(
      "../admin/Finance/Fees/MemberPaymentList" /* webpackChunkName: "MemberPaymentList" */
    ),
  {
    fallback: <Loading />,
  }
);
const ExpenseAddModify = loadable(
  () =>
    import(
      "../admin/Finance/Expense/ExpenseAddModify" /* webpackChunkName: "ExpenseAddModify" */
    ),
  {
    fallback: <Loading />,
  }
);
const ExpenseList = loadable(
  () =>
    import(
      "../admin/Finance/Expense/ExpenseList" /* webpackChunkName: "ExpenseList" */
    ),
  {
    fallback: <Loading />,
  }
);
const IncomeAddModify = loadable(
  () =>
    import(
      "../admin/Finance/OtherIncome/IncomeAddModify" /* webpackChunkName: "IncomeAddModify" */
    ),
  {
    fallback: <Loading />,
  }
);
const IncomeList = loadable(
  () =>
    import(
      "../admin/Finance/OtherIncome/IncomeList" /* webpackChunkName: "IncomeList" */
    ),
  {
    fallback: <Loading />,
  }
);
const CollectionTypes = loadable(
  () =>
    import(
      "../admin/Finance/CollectionTypes" /* webpackChunkName: "CollectionTypes" */
    ),
  {
    fallback: <Loading />,
  }
);
const Acctheadtype = loadable(
  () =>
    import(
      "../admin/Finance/Acctheadtype" /* webpackChunkName: "Acctheadtype" */
    ),
  {
    fallback: <Loading />,
  }
);
const Constituent = loadable(
  () =>
    import(
      "../admin/Members/Constituent/" /* webpackChunkName: "Constituent" */
    ),
  {
    fallback: <Loading />,
  }
);
const SlideShowList = loadable(
  () =>
    import(
      "../admin/SlideShow/SlideShowList" /* webpackChunkName: "SlideShowList" */
    ),
  {
    fallback: <Loading />,
  }
);
const SlidesList = loadable(
  () =>
    import(
      "../admin/SlideShow/SlidesList" /* webpackChunkName: "SlidesList" */
    ),
  {
    fallback: <Loading />,
  }
);
const Ad = loadable(() => import("../admin/Ad/" /* webpackChunkName: "Ad" */), {
  fallback: <Loading />,
});
const Social = loadable(
  () => import("../admin/Social/" /* webpackChunkName: "Social" */),
  {
    fallback: <Loading />,
  }
);
const SocialLink = loadable(
  () => import("../components/SocialLink" /* webpackChunkName: "SocialLink" */),
  {
    fallback: <Loading />,
  }
);
const ObList = loadable(
  () =>
    import("../admin/Finance/OpeningBalance/" /* webpackChunkName: "ObList" */),
  {
    fallback: <Loading />,
  }
);
const Gl = loadable(
  () => import("../admin/Finance/GeneralLedger/" /* webpackChunkName: "Gl" */),
  {
    fallback: <Loading />,
  }
);
const VotingDashboard = loadable(
  () =>
    import(
      "../admin/Voting/Dashboard" /* webpackChunkName: "VotingDashboard" */
    ),
  {
    fallback: <Loading />,
  }
);
const Election = loadable(
  () => import("../admin/Voting/Election" /* webpackChunkName: "Election" */),
  {
    fallback: <Loading />,
  }
);

const Router = ({ props }) => {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Popmsg key={1} />
            <AlertPopup key={2} />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/notify" element={<Notify />} /> */}

              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/register" element={<MemberRegister />} />
              <Route path="/login" element={<MemberLogin />} />
              <Route path="/events" element={<Events />} />
              <Route
                path="/events/rsvp/:eventId/"
                element={<EventAttendees />}
              />
              <Route
                path="/events/detail/:eventId/"
                element={<EventDetail />}
              />
              <Route
                path="/events/attendee/:eventId/"
                element={<EventAttendeeList />}
              />

              <Route path="/donate" element={<Donate />} />
              <Route path="/ad/:adType" element={<AdDisplay />} />

              <Route path="/terms" element={<TermsCondition />} />
              <Route path="/members" element={<MembersList />} />
              <Route path="/charter" element={<Charter />} />

              <Route path="/resetrequest" element={<Forgotpassword />} />
              <Route path="/resetpass/:vc/:em" element={<Resetpassword />} />

              <Route path="/page/:pageTitle" element={<Pages />} />

              {/* to get google token */}
              <Route path="/portfolio/:atoken/" element={<ViewAlbumList />} />
              <Route path="/portfolio" element={<ViewAlbumList />} />

              <Route
                path="/photos/:albumId/:title/:mediaCount/:aToken"
                element={<Photos />}
              />
              <Route path="/photos/:albumId/:aToken" element={<Photos />} />
              <Route path="/photos/:albumId" element={<Photos />} />

              <Route path="/about" component={About} />

              {/* ADMIN ROUTE */}
              <Route path="/admin" element={<Dashboard />} />

              <Route path="/admin/settings" element={<Settings />} />
              <Route path="/admin/menu" element={<Menu />} />
              <Route path="/admin/company" element={<Company />} />
              {/* POLL */}
              <Route path="/admin/poll" element={<PollList />} />
              <Route path="/pollvote/:pollId" element={<PollVote />} />
              <Route path="/pollresult/:pollId" element={<VoteCount />} />
              <Route path="/admin/pg/:pollId" element={<PollGroupList />} />
              <Route path="/admin/votecheck/:pollId" element={<VoteCheck />} />

              <Route
                path="/admin/pq/:groupId"
                element={<PollQuestionsList />}
              />

              <Route path="/admin/profile" element={<Profile />} />
              <Route
                path="/admin/gphotoapi/:atoken/"
                element={<GooglePhoto />}
              />
              <Route path="/admin/gphotoapi/" element={<GooglePhoto />} />

              <Route path="/admin/events" element={<EventsList />} />
              <Route path="/admin/voting" element={<VotingDashboard />} />
              <Route path="/admin/election" element={<Election />} />
              <Route path="/admin/notauthorized" element={<NotAuthorized />} />
              <Route path="admin/members" element={<Members />} />
              <Route path="admin/beneficiary" element={<BeneficiaryList />} />
              <Route path="admin/assets" element={<Assets />} />
              <Route path="admin/gl" element={<Gl />} />

              <Route
                path="admin/article/:addOrModify"
                element={<AddModifyArticle />}
              />
              <Route path="admin/resetmember/" element={<ResetMember />} />
              <Route path="admin/article" element={<ArticleList />} />
              <Route
                path="admin/article/:addOrModify/:articleId"
                element={<AddModifyArticle />}
              />

              <Route
                path="admin/members/:addOrModify/"
                element={<MemberAddModify />}
              />
              <Route
                path="admin/members/:addOrModify/:memberId"
                element={<MemberAddModify />}
              />

              {/* BTACA POST */}
              <Route path="admin/post/" element={<Post />} />
              <Route path="admin/post/:addOrModify/" element={<Post />} />
              <Route path="admin/post/:addOrModify/:id" element={<Post />} />

              {/* CONSTITUENT */}
              <Route path="admin/constituent/" element={<Constituent />} />
              <Route
                path="admin/constituent/:addOrModify/"
                element={<Constituent />}
              />
              <Route
                path="admin/constituent/:addOrModify/:id"
                element={<Constituent />}
              />
              <Route path="admin/ob/" element={<ObList />} />
              {/* FEES */}
              <Route path="admin/fees/" element={<MemberPaymentList />} />
              <Route
                path="admin/fees/:addOrModify/"
                element={<MemberPaymentAddModify />}
              />
              <Route
                path="admin/fees/:addOrModify/:feeId"
                element={<MemberPaymentAddModify />}
              />
              {/* EXPENSE */}
              <Route path="admin/income/" element={<IncomeList />} />
              <Route
                path="admin/income/:addOrModify/"
                element={<IncomeAddModify />}
              />
              <Route
                path="admin/income/:addOrModify/:id"
                element={<IncomeAddModify />}
              />

              {/* EXPENSE */}
              <Route path="admin/expense/" element={<ExpenseList />} />
              <Route
                path="admin/expense/:addOrModify/"
                element={<ExpenseAddModify />}
              />
              <Route
                path="admin/expense/:addOrModify/:id"
                element={<ExpenseAddModify />}
              />
              {/* COLLECTION NAMES */}
              <Route path="admin/constituent/" element={<Constituent />} />
              <Route
                path="admin/constituent/:addOrModify/"
                element={<Constituent />}
              />
              <Route
                path="admin/constituent/:addOrModify/:id"
                element={<Constituent />}
              />
              {/* ACCT HEAD TYPES */}
              <Route path="admin/ah/" element={<Acctheadtype />} />
              <Route path="admin/ah/:addOrModify/" element={<Acctheadtype />} />
              <Route
                path="admin/ah/:addOrModify/:id"
                element={<Acctheadtype />}
              />
              <Route path="admin/ad/" element={<Ad />} />
              <Route path="admin/social/" element={<Social />} />

              <Route path="admin/slideshow/" element={<SlideShowList />} />
              <Route
                path="admin/slides/:slideShowId"
                element={<SlidesList />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <div style={{ marginTop: "auto" }}>
              <Footer />
            </div>
            <div
              style={{
                position: "fixed",
                right: "0",
                top: 0,
                bottom: 0,
              }}
            >
              <div
                // style={{
                //   position: "relative",
                //   top: "50%",
                //   border: "1px solid #333",
                //   transform: "translateY(-50%)",
                //   paddingTop: 7,
                //   paddingBottom: 7,
                //   // backgroundColor: "rgb(234, 181, 20, 0.7)",
                //   borderTopLeftRadius: 8,
                //   borderBottomLeftRadius: 8,
                //   display:
                // }}
                className="flex justify-center items-center  h-screen"
              >
                <div className="flex justify-center items-center  px-0 py-2 border-l-gray-300 border-1 border-t-gray-300 border-b-gray-300 rounded-l-lg">
                  <SocialLink style={{ opacity: 1 }} />
                </div>
              </div>
            </div>
          </div>
        </>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default Router;
